// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  green: rgb(192, 202, 116);
  frost: rgb(203, 209, 195);
  gulstream: rgb(17, 130, 144);
*/

* {
  box-sizing: border-box;
  border: 0px solid black;
  /*background-color: rgb(0, 0, 255, 0.1);*/
}

html {
  min-width: 350px;
  margin: 0px;
  padding: 0px;
}

body {
  min-width: 350px;
  margin: 0px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./front/src/styles.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED;EACE,sBAAsB;EACtB,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf","sourcesContent":["/*\n  green: rgb(192, 202, 116);\n  frost: rgb(203, 209, 195);\n  gulstream: rgb(17, 130, 144);\n*/\n\n* {\n  box-sizing: border-box;\n  border: 0px solid black;\n  /*background-color: rgb(0, 0, 255, 0.1);*/\n}\n\nhtml {\n  min-width: 350px;\n  margin: 0px;\n  padding: 0px;\n}\n\nbody {\n  min-width: 350px;\n  margin: 0px;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
